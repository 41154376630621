import { Template } from "components/modecules";
import { useState } from "react";
import form from "utils/form";
import Questions from "./_components/questions";
import Register from "./_components/register";
import Welcome from "./_components/welcome";

export default function QuizzPage() {
  const [currentStep, setCurrentStep] = useState(0);

  const endQuiz = (event) => {
    event.preventDefault();
    localStorage.setItem("participation", JSON.stringify(true));
    event.target.submit();
  };

  const getStep = (section) => {
    if (section === 0) {
      return (
        <Template backgroundColor="bg-primary" dark>
          <Welcome onNext={() => setCurrentStep(1)} />
        </Template>
      );
    }

    if (section >= 1) {
      return (
        <Template>
          <Register onNext={() => setCurrentStep(2)} show={currentStep === 1} />
          <Questions show={currentStep === 2} />
        </Template>
      );
    }
  };

  return (
    <form method={form.method} action={form.url} onSubmit={endQuiz}>
      {getStep(currentStep)}
    </form>
  );
}
